<template>
  <v-form
    v-model="valid"
  >
    <v-expansion-panels
      mandatory
      flat
      v-if="template"
    >
      <v-expansion-panel>
        <v-expansion-panel-header> {{ $t('general') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col
              cols="12"
            >
              <v-text-field
                :label="$t('name') + ' *'"
                type="text"
                required
                v-model="template.name"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                :label="$t('description') + ' *'"
                required
                type="text"
                v-model="template.description"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-if="!editing"
                :label="$t('namespace') + ' *'"
                required
                type="text"
                v-model="template.namespace"
                :rules="[rules.required]"
                :items="templateNamespaces"
                outlined
                :loading="loadingInitialData"
                :disabled="loadingInitialData"
                :hint="$t('changingNamespaceWillOverwriteAnyChangesToContentWithDefaultContent')"
                persistent-hint
              />
              <v-select
                v-else
                :label="$t('namespace') + ' *'"
                required
                type="text"
                v-model="template.namespace"
                :rules="[rules.required]"
                :items="templateNamespaces"
                outlined
                :loading="loadingInitialData"
                :disabled="loadingInitialData"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                :label="$t('type') + ' *'"
                required
                type="text"
                v-model="template.type"
                :rules="[rules.required]"
                :items="templateTypes"
                outlined
                :loading="loadingInitialData"
                :disabled="loadingInitialData"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                :label="$t('contentType') + ' *'"
                required
                type="text"
                v-model="template.contentType"
                :rules="[rules.required]"
                :items="templateContentTypes"
                outlined
                :loading="loadingInitialData"
                :disabled="loadingInitialData"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                :label="$t('trigger') + ' *'"
                required
                type="text"
                v-model="template.trigger"
                :rules="[rules.required]"
                :items="templateTriggers"
                outlined
                :loading="loadingInitialData"
                :disabled="loadingInitialData"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header> {{ $t('content') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col
              cols="12"
            >
              <MonacoEditor
                v-model="template.content"
                width="100%"
                height="500px"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-form>
</template>

<script>
export default {
	name: 'TemplateForm',
	props: {
		value: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			templateTriggers: null,
			templateTypes: null,
			templateContentTypes: null,
			templateNamespaces: null,
      loadingInitialData: true,
			valid: false,
			defaultTemplateContents: null,
			oldNamespace: null,
			forceRenderKey: 0
		}
	},
	components: {
		'MonacoEditor':() => import('@/components/common/MonacoEditor')
	},
	computed: {
		template: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		},
		rules() {
			return this.$store.getters.rules
		},
		editing() {
			return this.template.uuid ? true : false
		}
	},
	watch: {
		template: {
			handler(value) {
				if(!this.editing) {
					if(this.oldNamespace !== value.namespace) {
							//Find the default template content for appropriate namespace if creating new template
							this.defaultTemplateContents.find(template => {
								if(template.namespace === value.namespace) {
									this.template.content = template.content
								}
							})
							this.oldNamespace = value.namespace
					}
				}
				if(!this.value.name || !this.value.description || !this.value.content) {
					this.$emit('valid', false)
					return
				}
				if(!this.value.type || !this.templateTypes.includes(this.value.type)) {
					this.$emit('valid', false)
					return
				}
				if(!this.value.trigger || !this.templateTriggers.includes(this.value.trigger)) {
					this.$emit('valid', false)
					return
				}
				if(!this.value.contentType || !this.templateContentTypes.includes(this.value.contentType)) {
					this.$emit('valid', false)
					return
				}
				if(!this.value.namespace || !this.templateNamespaces.includes(this.value.namespace)) {
					this.$emit('valid', false)
					return
				}
				this.$emit('valid', true)
			},
			deep: true
		}
  },
	created() {
		const triggers = this.$store.dispatch('getEnumNameValues', 'TemplateTrigger')
		const types = this.$store.dispatch('getEnumNameValues', 'TemplateType')
		const templateContent = this.$store.dispatch('getEnumNameValues', 'TemplateContentType')
		const templateNamespaces = this.$store.dispatch('getEnumNameValues', 'TemplateNamespace')
		const defaultTemplateContents = this.$store.dispatch('getDefaultTemplateContents')
		Promise.all([triggers, types, templateContent, templateNamespaces, defaultTemplateContents]).then((values) => {
      //Filtering enum values that we know work
			this.templateTriggers = values[0].filter(trigger => trigger === 'template')
			this.templateTypes = values[1].filter(type => type === 'pdf')
			this.templateContentTypes = values[2].filter(contentType => contentType === 'html')
			this.templateNamespaces = values[3].filter(namespace => namespace === 'giftcard')
			this.defaultTemplateContents = values[4]
      this.loadingInitialData = false
		})
	}
}
</script>
